'use client'

import { ASSET_PREFIX } from '@/configs/constants'

import { LinkWithTracker } from './LinkWithTracker'

export interface FullScreenErrorProps {
  statusCode: number
  title: string
  description: string
  srText?: string
  imageUrl?: string
  imageAlt?: string
}

export function FullScreenError({
  statusCode,
  title,
  description,
  srText = 'Error page',
  imageUrl = `${ASSET_PREFIX}/images/img-error-fixing-maintenance.svg`,
  imageAlt = 'Opps, error happened',
}: FullScreenErrorProps) {
  return (
    <div className="flex flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <LinkWithTracker
            href="/"
            passHref
            prefetch={false}
            className="inline-flex"
          >
            <span className="sr-only">{srText}</span>
            <img className="h-48 w-auto" src={imageUrl} alt={imageAlt} />
          </LinkWithTracker>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-primary">
              {statusCode || 500} error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
              {title}
            </h1>
            <p className="mt-2 text-base text-gray-70">{description}</p>
            <div className="mt-6">
              <LinkWithTracker
                href="/"
                passHref
                prefetch={false}
                className="text-base font-medium text-primary hover:text-primary/70"
              >
                Kembali ke beranda<span aria-hidden="true"> &rarr;</span>
              </LinkWithTracker>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
