import { FullScreenError } from '@/components/FullScreenError'

import { ASSET_PREFIX } from '@/configs/constants'

export default function PageError5xx({ statusCode = 500 }) {
  return (
    <FullScreenError
      statusCode={statusCode || 500}
      srText="Page error illustration"
      imageUrl={`${ASSET_PREFIX}/images/img-error-fixing-maintenance.svg`}
      imageAlt="Opps, error happened"
      title="Terjadi kesalahan"
      description="Server kami gagal memproses permintaan Anda. Tim kami akan segera mengecek dan memperbaikinya."
    />
  )
}
