'use client'

import PageError5xx from '@/modules/error/5xx'

import { BotProvider } from '@/components/BotDetector/BotProvider'

export default function GlobalErr() {
  return (
    <BotProvider value={false}>
      <PageError5xx />
    </BotProvider>
  )
}
